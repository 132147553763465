import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import Panel from "./core/Panel"
import ContactDetail from "./core/ContactDetail"
import FormElement from "./core/FormElement"
import axios from "../axios"
import isEmail from 'validator/es/lib/isEmail';

const ContactForm = () => {
  const form = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/contact-form/" } }
        ) {
          edges {
            node {
              frontmatter {
                title
                text
                telLabel
                telNumber
                emailLabel
                email
                fields {
                  name {
                    label
                    type
                    required
                  }
                  email {
                    label
                    type
                    required
                  }
                  reqs {
                    label
                    type
                    required
                  }
                }
                submitText
              }
            }
          }
        }
      }
    `
  )

  const { node } = form.allMdx.edges[0]
  const [fields, onChange] = useState(node.frontmatter.fields)
  const [confirm, onSubmit] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const validate = (fields) => {
    const { name, email, reqs: message } = fields
    return name.value && name.value !== ""
      && email.value && email.value !== "" && isEmail(email.value)
      && message.value && message.value !== ""
      && message.value.match(/[a-zA-Z]/g) 
        ? true 
        : false
  }

  // on submit
  let handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, reqs: message } = fields

    if(isValid) {
      axios.post("", {
        blocks : [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: "You have a new message from the Lunar Site"
            }
          },
          {
            type: "divider"
          },
          {
            type : "section",
            text: {
                type: "mrkdwn",
                text: `*Name:* ${name.value}\n*Email:* ${email.value}\n*Message:* ${message.value}`
            }
          }
      ]
      }).then(() => {
        onSubmit(true);
      })
    }
  }

  return (
    <Panel
      id="contact-form"
      key={node.id}
      className={`contact-form${confirm ? " submitted" : ""}`}
      color="grey"
      title={node.frontmatter.title}
      body={
        <>
          <p className="contact-form__text">{node.frontmatter.text}</p>
          <ContactDetail
            label={node.frontmatter.telLabel}
            href={`tel:${node.frontmatter.telNumber}`}
          >
            {node.frontmatter.telNumber}
          </ContactDetail>
          <ContactDetail
            label={node.frontmatter.emailLabel}
            href={`mailto:${node.frontmatter.email}`}
          >
            {node.frontmatter.email}
          </ContactDetail>
        </>
      }
    >
      { !confirm && 
        <form
          onSubmit={e => {
            handleSubmit(e)
          }}
          className="contact-form__form form"
        >
          {Object.keys(fields).map(key => (
            <FormElement
              key={key}
              {...node.frontmatter.fields[key]}
              name={key}
              onChange={e => {
                const newFields = { ...fields }
                newFields[key] = { ...newFields[key], value: e.target.value }
                onChange(newFields)
                setIsValid(validate(newFields))
              }}
            />
          ))}
          <div className="contact-form__submit">
            <FormElement
              type="submit"
              value={node.frontmatter.submitText}
              className="button button-secondary"
              disabled={!isValid}
            />
          </div>
        </form>
      }
      
      { confirm &&
        <p className="contact-form__confirm">Thank you, your message has been sent.</p>
      }
    </Panel>
  )
}

export default ContactForm
