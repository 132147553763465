import React from "react";
import PropTypes from "prop-types";

const ContactDetail = ({ label, children, href }) => {
  return (
    <div className="contact-detail">
      <p className="contact-detail__label">{ label }</p>
      <p className="contact-detail__value">
        <a href={ href }>{ children }</a>
      </p>
    </div>
  );
};

ContactDetail.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default ContactDetail;
