import React from "react"
import PropTypes from "prop-types"

const FormElement = ({
  label,
  type,
  name,
  value,
  required,
  onChange,
  className = "",
  disabled = false
}) => (
  <div className={`form__group form__group--${type}`}>
    <label htmlFor={name} className="form__label">
      {label} {required ? ' *' : ''}
    </label>
    {type === "textarea" ? (
      <textarea
        className={`form__input form__input--textarea ${className}`}
        onChange={onChange}
        name={name}
        id={name}
        required={required}
      >
        {value}
      </textarea>
    ) : type === "submit" ? (
      <button
        data-text="Send"
        className={`form__input ${className}`}
        onChange={onChange}
        type={type}
        name={name}
        id={name}
        value={value}
        disabled={disabled}
      >{value}</button>
    ) : 
      <input
        className={`form__input ${className}`}
        onChange={onChange}
        type={type}
        name={name}
        id={name}
        value={value}
        required={required}
      />
    }
  </div>
)

FormElement.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default FormElement
